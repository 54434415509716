import React, { Component } from 'react'
import Helmet from 'react-helmet';
import Column from '../shared/components/layout/column';
import Card from '../shared/components/card/card';
import Row from '../shared/components/layout/row';
import GoogleAdsenseAd from '../shared/components/google/google-adsense-ad';

const ADVERTISEMENT_IDS = {
    HEADER: 'header',
    FOOTER: 'footer'
}

const WISE_REFERRAL_AD_URL = 'https://link.culleystudios.com/cs-wise-referral';
const WISE_REFERRAL_AD_URL_RAW = 'https://link.culleystudios.com/cs-wise-referral'

const REFERRAL_AD_PLACEMENTS = [ADVERTISEMENT_IDS.HEADER, ADVERTISEMENT_IDS.FOOTER];

const REFERRAL_LABELS = {}

const REFERRAL_REDIRECTS = {
    [WISE_REFERRAL_AD_URL_RAW]: WISE_REFERRAL_AD_URL,
}

export default class Payment extends Component {

    getAdvertisementFields = (advertisementId) => REFERRAL_AD_PLACEMENTS.includes(advertisementId) ? {
        label: REFERRAL_LABELS[advertisementId] ?? 'Referral',
        backgroundClass: 'wise-referral-container',
    } : {
        label: 'Advertisement',
        backgroundClass: 'bg-tertiary',
    }

    render() {
        const handleRedirect = (event) => {
            event.preventDefault();
            window.open(REFERRAL_REDIRECTS[event.currentTarget.href] ?? event.currentTarget.href, "_blank");
        }

        const headerAdFields = this.getAdvertisementFields(ADVERTISEMENT_IDS.HEADER);
            const headerAd = REFERRAL_AD_PLACEMENTS.includes(ADVERTISEMENT_IDS.HEADER) ? (
                <a className='d-block w-10' href={WISE_REFERRAL_AD_URL_RAW} target="_blank" rel="noopener noreferrer" onClick={handleRedirect}>
                    <div className="m-auto px-sm flex">
                        <img className="h-10" src="https://wise-creative.prf.hn/source/camref:1011l3CNzC/creativeref:1011l103169" alt="Wise - A cheaper way to make international business payments" />
                    </div>
                </a>
            ) : (
                <GoogleAdsenseAd className="m-auto py-sm">
                    <ins className="adsbygoogle"
                        style={{display: "inline-block", width: "728px", height: "90px"}}
                        data-ad-client="ca-pub-4548126687571070"
                        data-ad-slot="9951334107"
                        data-full-width-responsive="true"></ins>
                </GoogleAdsenseAd>
            );

        const footerAdFields = this.getAdvertisementFields(ADVERTISEMENT_IDS.FOOTER);
        const footerAd = REFERRAL_AD_PLACEMENTS.includes(ADVERTISEMENT_IDS.FOOTER) ? (
            <a className='d-block w-10' href={WISE_REFERRAL_AD_URL} target="_blank" rel="noopener noreferrer" onClick={handleRedirect}>
                <div className="px-sm flex">
                    <img className="h-10" src="https://wise-creative.prf.hn/source/camref:1011l3CNzC/creativeref:1011l103162" alt="Wise - Real fast, real fair, get the mid-market exchange rate and fair fees" />
                </div>
            </a>
        ) : (
            <GoogleAdsenseAd className="m-auto p-sm">
                <ins className="adsbygoogle"
                    style={{display: "block" }}
                    data-ad-client="ca-pub-4548126687571070"
                    data-ad-slot="1253866251"
                    data-ad-format="horizontal"
                    data-full-width-responsive="true"></ins>
            </GoogleAdsenseAd>
        );

        return (
            <div className="mx-lg">
                <Helmet>
                    <title>Culley Studios | Payment</title>
                </Helmet>
                <Column className="flex-a-items-center w-max m-auto overflow-hidden">
                    <Row className="flex-mb-wrap w-10 hide-ad-mb gap-md">
                        <Row className={`flex-start flex-wrap flex-a-items-center ${headerAdFields.backgroundClass} r-sm w-10`}>
                            {headerAd}
                        </Row>
                    </Row>
                    <br/>
                    <Row className="flex-mb-wrap w-10 gap-md float-in">
                        <Card className="w-10 w-max float-in"
                            headerClass="bg-tertiary"
                            header={
                                <div>
                                    <h2 className="mx-md">Payment completed</h2>
                                    <hr className="primary w-10 m-none"/>
                                </div>
                            }
                            bodyClass="bg-secondary"
                            body={
                                <div className="p-lg">
                                    <p>
                                    Your payment has been completed. Rest assured, it will be processed, and your purchased items will be on their way to you shortly.
                                    </p>
                                </div>
                            }
                        />
                        <br/>
                    </Row>
                    <br className="d-mb-none"/>
                    <Row className="flex-mb-wrap w-10 gap-md float-in d-none d-mb-block">
                        <Card className="bg-secondary"
                            body={
                                <div className={footerAdFields.backgroundClass}>
                                    { footerAd }
                                </div>
                            }
                            footerClass="bg-tertiary"
                            footer={
                                <Column>
                                    <hr className="tertiary m-none w-10"/>
                                    <Row className="m-sm text-center">
                                        <span className="d-inline">{footerAdFields.label}</span>
                                    </Row>
                                </Column>
                            }>
                        </Card>
                    </Row>
                    <br />
                </Column>
            </div>
        )
    }
}