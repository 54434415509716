import React, { useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import Column from '../shared/components/layout/column';
import Row from '../shared/components/layout/row';
import Banner from '../shared/components/banner/banner';
import Card from '../shared/components/card/card';
import Request from '../shared/classes/communication/request';
import Badge, { BADGE_RIGHT } from '../shared/components/badge/badge';
import { useFloatInTimeout } from '../shared/hooks/use-float-in-timeout';
import LoadingOverlay from '../shared/components/loading-overlay/loading-overlay';
import { floatInElements } from '../shared/scripts/animations/float-in/float-in';

const TEST_ENVIRONMENT_BANNER = {
    title: 'You are using the test environment',
    text: 'You are currently using the test environment, where all transactions are simulated and will not result in actual charges. Avoid entering real payment or personal information while using this environment.'
}

const PAYMENT_OPTION_MAPPING = {
    PAYPAL: {
        label: 'PayPal',
        logoUrl: 'https://culleystudios.com/images/cs-payments/paypal/paypal-logo.png',
        recommended: false,
        description: 'PayPal offers secure, fast online payments and money transfers with support for multiple currencies and platforms.',
        checkoutLabel: 'Pay with PayPal',
    },
    STRIPE: {
        label: 'Stripe',
        logoUrl: 'https://culleystudios.com/images/cs-payments/stripe/stripe-logo.png',
        recommended: false,
        description: 'Pay securely with Stripe. Simply enter your details and complete your payment quickly and safely.',
        checkoutLabel: 'Pay with Stripe',
    },
    WISE: {
        label: 'Wise',
        logoUrl: 'https://culleystudios.com/images/cs-payments/wise/wise-logo.png',
        recommended: true,
        description: 'Pay securely with Wise. Enjoy low fees, fast transfers, and great exchange rates for your payment.',
        checkoutLabel: 'Pay with Wise',
    }
}

const CheckoutPaymentOption = ({ checkoutId, enabled, platform }) => {
    const {
        checkoutLabel,
        description,
        label,
        logoUrl,
        recommended,
    } = PAYMENT_OPTION_MAPPING[platform]

    const handleOnClick = (event) => {
        event.preventDefault()
        window.location.assign(`https://pay.culleystudios.com/checkout/${checkoutId}?platform=${platform}`)
    }

    return (
        <div className="w-4 w-mb-10 float-in">
            {!enabled && (
                <Badge position={BADGE_RIGHT} x="-5" y="-10">
                    <div className="critical p-sm r-sm">
                        <span>Unavailable</span>
                    </div>
                </Badge>
            )}
            {enabled && recommended && (
                <Badge position={BADGE_RIGHT} x="-5" y="-10">
                    <div className="secondary p-sm r-sm">
                        <span>Recommended</span>
                    </div>
                </Badge>
            )}
            <Card className="bg-secondary"
                headerClass="bg-tertiary"
                header={
                    logoUrl ? <img src={logoUrl} alt={`${label} Logo`} /> :
                        <div>
                            <h2 className="mx-md">{label}</h2>
                            <hr className="primary w-10"/>
                        </div>
                }
                body={
                    <div className='mx-sm'>
                        <p>{description}</p>
                        <Row className="text-center">
                            <button className="btn btn-secondary px-lg r-circle w-6" disabled={!enabled} onClick={handleOnClick}>
                                {checkoutLabel}
                            </button>
                        </Row>
                        <br />
                    </div>
                }>
            </Card>
        </div>
    )
}

const Checkout = (props) => {
    const { checkoutId } = props.match.params

    const loadingOverlay = useRef()
    const [started, setStarted] = useState(true)
    const [redirect, setRedirect] = useState(false)
    const [store, setStore] = useState()
    const [banner, setBanner] = useState('')

    useEffect(() => {
        if (!store) return

        const isTest = !!store?.paymentOptions?.find(paymentOption => paymentOption.environment === 'TEST')
        if (!isTest) return

        setBanner(TEST_ENVIRONMENT_BANNER)
    }, [store])

    useEffect(() => {
        if (!started) return
        
        const redirectHandler = (error) => {
            loadingOverlay.current.setAnimating(false);
            console.error(error);
            setRedirect(true);
        }

        loadingOverlay.current.setAnimating(true);
        setStarted(false);

        new Request(`https://api.culleystudios.com/api/v1/cs-payments/checkout/${checkoutId}/store`, 15000, (data) => {
            const response = JSON.parse(data.target.response)

            if (response?.statusCode === 404) {
                setRedirect(true)
                return
            }

            loadingOverlay.current.setAnimating(false);
            setStore(response)
            floatInElements(true)
        }, redirectHandler, redirectHandler).getJSON();
    }, [started])

    useFloatInTimeout(3500)
    
    return (
        <div className="mx-lg">
            <Helmet>
                <title>Culley Studios | Checkout</title>
            </Helmet>
            {redirect === true && <Redirect to="/" />}
            <Column className="flex-a-items-center w-max m-auto overflow-hidden">
                {banner && (
                    <div className="float-in">
                        <Column className="warning m-auto r-sm w-8 w-mb-10">
                            <Banner className="m-sm" {...banner} />
                        </Column>
                        <br />
                    </div>
                )}
                <div className="float-in">
                    <h1 className="text-center">Select payment provider</h1>
                    <hr className="primary m-auto w-10" />
                    <br/>
                </div>
                <br/>
                <Row className="flex-wrap w-10 gap-md">
                    {store?.paymentOptions?.length ? store?.paymentOptions.map(paymentOption =>
                        <CheckoutPaymentOption checkoutId={checkoutId} {...paymentOption} />
                    ) : (
                        <div className="loading-content"/>
                    )}
                    <LoadingOverlay ref={loadingOverlay} delay="100" />
                </Row>
                <br/>
            </Column>
        </div>
    );
}

export default Checkout;