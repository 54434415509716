import React from 'react'
import Helmet from 'react-helmet';
import Row from '../../shared/components/layout/row';
import Badge, { BADGE_RIGHT } from '../../shared/components/badge/badge';
import Card from '../../shared/components/card/card';

const JACOB_LINKEDIN_URL = "https://www.linkedin.com/in/jacob-culley-a87b31127/";

const Team = () => (
    <div>
        <Helmet>
            <title>Culley Studios | Meet our Team</title>
        </Helmet>
        <div className="mx-lg">
            <div className="float-in">
                <h1 className="text-center">Meet our Team</h1>
                <hr className="primary m-auto w-4 w-mb-8" />
                <br/>
            </div>
            <br/>
            <Row className="flex-wrap w-10 gap-md">
                <div className="w-6 w-mb-10 float-in">
                    <Badge position={BADGE_RIGHT} x="-5" y="-10">
                        <Row>
                            <div className="secondary p-sm r-sm mx-sm">
                                <span>Owner</span>
                            </div>
                            <div className="secondary p-sm r-sm">
                                <span>Software Engineer</span>
                            </div>
                        </Row>
                    </Badge>
                    <Card className="bg-secondary"
                        headerClass="bg-tertiary"
                        header={
                            <div>
                                <h2 className="mx-md">Jacob Culley</h2>
                                <hr className="primary w-10"/>
                            </div>
                        }
                        body={
                            <div className="mx-md">
                                <p>
                                    A productive, detail-oriented Software Engineer who thinks critically to solve complex problems. Possess multiple years of industry experience in all stages of the systems development life cycle from conceptualization to production implementation. Highly experienced with Java, JavaScript, TypeScript, Python, and relational databases. Capable of quickly adapting and embracing new technologies and concepts. Supportive and enthusiastic team player willing to take on new challenges. Able to effectively manage business relations utilizing a strong background in client-oriented positions.
                                </p>
                                <a className="p-md" href={JACOB_LINKEDIN_URL}>
                                    <img src="/images/social/linkedin-icon.png" alt="LinkedIn Icon" className="flex-a-self-center btn-img-md"/>
                                </a>
                            </div>
                        }>
                    </Card>
                </div>
            </Row>
            <br/>
        </div>
    </div>
);

export default Team;