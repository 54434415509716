import React from 'react'

const Banner = ({ title, text, className }) => {
    return (
        <div className={className}>
            {title && <h3>You are using the test environment</h3>}
            {text && <p>You are currently using the test environment, where all transactions are simulated and will not result in actual charges. Avoid entering real payment or personal information while using this environment.</p>}
        </div>
    )
}

export default Banner