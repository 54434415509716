import React from 'react';
import { Route } from 'react-router-dom';
import EventRouter from './shared/components/event-router/event-router'
import PageHeader from './layout/header/header';
import PageFooter from './layout/footer/footer';
import { getSkeletonColor, getSkeletonHighlightColor, loadTheme } from './shared/scripts/theme';

import Home from './pages/home/home';
import Jacob from './pages/team/jacob'
import Documents from './pages/docs/documents';
import Team from './pages/team/team';
import Checkout from './pages/checkout';
import Store from './pages/store/store';
import Connect from './pages/connect/connect';
import Payment from './pages/payment';
import GoogleAnalytics from './shared/components/google/google-analytics';
import PrivacyPolicy from './pages/privacy-policy';
import { SkeletonTheme } from 'react-loading-skeleton';

const GOOGLE_ANALYTICS_ID = 'UA-145060009-1';

function App() {
	loadTheme();
	return (
		<EventRouter>
			<SkeletonTheme color={getSkeletonColor()} highlightColor={getSkeletonHighlightColor()}>
				<GoogleAnalytics id={GOOGLE_ANALYTICS_ID}>
					<div className="bg-primary">
						<PageHeader />
			
						<Route exact path="/" component={Home} />
						<Route path="/home" component={Home} />
						<Route path="/team" component={Team} />
						<Route path="/jacob-culley" component={Jacob} />
						<Route path="/team/jacob" component={Jacob} />
						<Route path="/checkout/:checkoutId" component={Checkout} />
						<Route path="/store" component={Store} />
						<Route path="/payment" component={Payment} />
						<Route path="/docs" component={Documents} />
						<Route path="/connect" component={Connect} />
						<Route path="/privacy" component={PrivacyPolicy} />
						
						<PageFooter />
					</div>
				</GoogleAnalytics>
			</SkeletonTheme>
		</EventRouter>
	);
}

export default App;
